/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {

    'breakpoint': 1025,

    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        $("#cookie_policy_btn").click(function(e) {
          e.preventDefault();
          var cname = '_cookie_privacy_accepted';
          var cvalue = 'true';
          var exdays = 365;

          var d = new Date();
          d.setTime(d.getTime() + (exdays*24*60*60*1000));
          var expires = "expires="+d.toUTCString();
          document.cookie = cname + "=" + cvalue + "; " + expires + ";path=/";
          $("#cookie_policy").remove();
          $("#cookie_policy_popup").remove();
          document.location.reload();
        });

        $(window).load(function() {
          $('body.archive.woocommerce ul.products').eq(0).masonry({
            columnWidth: '.product',
            itemSelector: '.product'
          });
        });

        $(".fancy-item").fancybox();

        $(".navbar-primary > li.dropdown")
            .on("shown.bs.dropdown", function() {
              if($(window).width() < Sage.breakpoint) {
                var top = $(this).offset().top;
                $("body, html").animate({
                  scrollTop: top - 15
                }, 500);
              }
            });
        $(".navbar-primary li.dropdown a")
            .on("click", function() {
              if(!$(this).hasClass("lvl-1") || $(window).width() >= Sage.breakpoint) {
                document.location.href = $(this).attr("href");
              }
            });

        /*
        var audio = $("<audio>").appendTo("body");

        $("<source>").attr({
          'src': variables.theme_url + '/dist/sounds/beep.mp3',
          'volume':1
        }).appendTo(audio);
        $("<source>").attr({
          'src': variables.theme_url + '/dist/sounds/beep.ogg',
          'volume':1
        }).appendTo(audio);

        if( !!(audio[0].canPlayType && audio[0].canPlayType('audio/mpeg;').replace(/no/, '')) ) {
          $("a").mouseenter(function() {
            audio[0].play();
          });
        }
        */
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        $(".home-slider .carousel-images").owlCarousel({
          animateIn: 'fadeIn',
          animateOut: 'fadeOut',
          items: 1,
          nav: false,
          dots: false,
          loop: true,
          autoplay: true
        });
        $(".home-slider .carousel-texts").owlCarousel({
          animateIn: 'fadeInLeft',
          animateOut: 'fadeOutRight',
          items: 1,
          nav: false,
          dots: false,
          loop: true,
          autoplay: true
        });
        $('.brands-carousel').owlCarousel({
          margin:10,
          loop:true,
          autoWidth:true,
          items:4,
          autoplay: true
        });
        setInterval(function() {
          $('.home-products').masonry({
            columnWidth: '.masonry-item-square',
            itemSelector: '.masonry-item'
          });
        }, 300);
        /*
        $(window).scroll(function() {
          if($(window).scrollTop() + $(window).height() === $(document).height()) {
            Sage.home.loadMore();
          }
        });
        */
        $("#product-scroll-signal a.cover").click(function(e) {
          e.preventDefault();
          Sage.home.loadMore();
        });
      },
      loadMore: function() {
        $(".to-load").each(function(index) {
          if(index<10) {
            var img = $(this).find("img");
            img.attr("src", img.attr("data-src"));
            $(this).removeClass("to-load");
          }
        });
        if($(".to-load").length===0) {
          $("#product-scroll-signal").remove();
        }
      }
    },
    'single_product': {
      init: function() {

      },
      finalize: function() {
        $(".product .variations select").each(function() {
          $(this).find("option").eq(1).prop("selected", true).trigger("change");
        });
      }
    },
    'post_type_archive_product': {
      init: function() {

      },
      finalize: function() {
        $(".product .price .amount:first-child").after(" ");
        $(".product .price .amount:last-child").before(" ");
      }
    },
    // About us page, note the change from about-us to about_us.
    'page_template_template_contact': {
      init: function() {
        // JavaScript to be fired on the about us page
        $("form").each(function () {
          if (!$(this).hasClass("initialized")) {
            var form = $(this);
            form.validate({
              ignore: (form.attr("data-ignore-hidden") ? ":hidden" : []),
              errorPlacement: function (error, element) {
                form.find("label[for=" + element.attr("name").replace("[]", "") + "]").append(error);
              }
            });
            $(this).addClass("initialized");
          }
        });

        $("#contact-form").submit(function () {
          if ($(this).valid()) {
            $("#contact-form").addClass("sending");
            $.ajax({
              type: "POST",
              url: variables.ajax_url,
              data: $(this).serialize(),
              dataType: "json",
              success: function (data) {
                $("#contact-form").replaceWith("<div align='center' class='alert alert-" + (data.res === "1" ? "success" : "danger") + "'>" + data.message + "</div>");
              }
            });
          }
          return false;
        });

        $.getScript("https://maps.googleapis.com/maps/api/js?key="+variables.map_api_key+"&language=it", function() {
          var mapContainer = $("#map");
          var myLatLng = {lat: parseFloat(mapContainer.attr("data-lat")), lng: parseFloat(mapContainer.attr("data-lng"))};

          var customMapType = new google.maps.StyledMapType([{"elementType":"geometry","stylers":[{"visibility":"simplified"},{"hue":"#ff0000"}]},{"featureType":"road","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit.station","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"poi","stylers":[{"visibility":"off"}]},{"featureType":"administrative","stylers":[{"visibility":"simplified"}]},{"featureType":"water","stylers":[{"visibility":"off"}]}], {
                name: 'Custom Style'
              });
          var customMapTypeId = 'custom_style';

          var map = new google.maps.Map(mapContainer[0], {
            zoom: 15,
            center: myLatLng,
            scrollwheel: false,
            minZoom: 10,
            mapTypeControlOptions: {
              mapTypeIds: [google.maps.MapTypeId.ROADMAP, customMapTypeId]
            }
          });

          var pinImage = new google.maps.MarkerImage( variables.theme_url + "/dist/images/chart.png",
              new google.maps.Size(21, 34),
              new google.maps.Point(0, 0),
              new google.maps.Point(10, 34));

          var marker = new google.maps.Marker({
            position: myLatLng,
            map: map,
            icon: pinImage
          });

          map.mapTypes.set(customMapTypeId, customMapType);
          map.setMapTypeId(customMapTypeId);
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
